import React, { useEffect, useState } from "react";

import { 
  Page,
  Link,
  BlockStack,
  DataTable,
  InlineError,
  Card,
  Box,
  Text,
  EmptyState,
 } from "@shopify/polaris";

import { formatDate } from "../../../services/utils";
import { getCuratedCollections, getProductCategories } from "../../../services/api";
import NewCuratedCollectionModal from "./NewCuratedCollectionModal";
import CollectionStatus from "../common/CollectionStatus";
import PageSpinner from "../../../components/page_spinner/PageSpinner";

import { GENERIC_ERROR_MESSAGE, paths, images } from "../../../constants";

export default function CuratedCollectionsList() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [curatedCollections, setCuratedCollections] = useState([]);
  const [productCategories, setProductCategories] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);

    getProductCategories().then((res) => {
      let categoriesMap = {};

      res.forEach((category) => {
        categoriesMap[category.key] = category.name;
      });

      setProductCategories(categoriesMap);
    }).catch(error => {
      setError(error.msg || GENERIC_ERROR_MESSAGE)
    });

    getCuratedCollections()
      .then((res) => {
        setCuratedCollections(res);
      })
      .catch(error => {
        setError(error.msg || GENERIC_ERROR_MESSAGE)
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const rowMarkup = curatedCollections.map((collection) => {
    return [
      collection.id,
      <Link
        key={`link-${collection.id}`}
        url={paths.curatedCollection.main.replace(':collectionId', collection.id)}>
        {collection.title}
      </Link>,
      <CollectionStatus status={collection.status} />,
      collection.products_count,
      collection.categories.map((category, index) => (
        <Text key={`category-${index}`} variant="bodysm" as="p">{productCategories[category]}</Text>
      )),
      formatDate({
        value: new Date(collection.created_at),
        strftime: "%b %d, %Y",
      }),
    ];
   });

   const emptyStateMarkup = (
    <EmptyState
      heading="No Curated Collections yet"
      image={images.emptyStateImage}
    />
  );

  return (
    <Page
      fullWidth
      title="Curated Collections"
      verticalAlign="middle"
      primaryAction={{
        content: '+ Create new',
        onClick: () => setIsModalOpen(true),
      }}
    >

      <NewCuratedCollectionModal
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />

      <Box paddingBlockEnd="1200">
        <BlockStack gap="500" align="space-around">

          {error && <InlineError message={error} />}

          {!error && isLoading && (
            <PageSpinner />
          )}

          {!error && !isLoading && !Boolean(curatedCollections.length) && (
            <Card>
              {emptyStateMarkup}
            </Card>
          )}
        
          {!error && !isLoading && Boolean(curatedCollections.length)  && (
              <Card padding="0">
                <DataTable
                  itemCount={curatedCollections.length}
                  columnContentTypes={[
                    'text',
                    'text',
                    'text',
                    'text',
                    'text',
                    'numeric',
                  ]}
                  stickyHeader
                  verticalAlign="middle"
                  headings={[
                    'ID',
                    'Title',
                    'Status',
                    'Total products',
                    'Product categories',
                    'Created',
                  ]}
                  rows={rowMarkup}
                >
                </DataTable>
              </Card>
            )}
      </BlockStack>
    </Box>
  </Page>
  );
}
