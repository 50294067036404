/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";

import {
  Card,
} from '@shopify/polaris';
import { formatDate } from "../../../../services/utils";
import { CardField } from "../../../../components/card";
import AutoCommissionEnrollmentSource from "./AutoCommissionEnrollmentSource";

export default function MerchantPayouts({ merchant }) {
  const { shopify_store } = merchant;
  const {
    auto_payouts_enrollment_status,
    auto_commission_payout_enrolled_at,
    auto_commission_payout_left_at,
    auto_commission_payout_enrollment_source,
  } = shopify_store;

  const enrolledAutomaticPayouts = [
    "show_manual_payouts",
    "do_not_show_manual_payouts",
  ].includes(auto_payouts_enrollment_status);

  return (
    <Card>
      <CardField title="Currently Using">
        {enrolledAutomaticPayouts
            ? "Automatic Payments"
            : "Manual Payments"}
      </CardField>

      <CardField title="Automatic Payments Enrollment Status">
        <code>{auto_payouts_enrollment_status}</code>
      </CardField>

      {auto_commission_payout_enrolled_at && (
        <CardField title="Automatic Payments Enrolled At">
          {formatDate({
            value: auto_commission_payout_enrolled_at,
            strftime: "%b %d, %Y, %H:%M",
          })}
        </CardField>
      )}

      {auto_commission_payout_left_at && (
        <CardField title="Automatic Payments Disabled At">
          {formatDate({
            value: auto_commission_payout_left_at,
            strftime: "%b %d, %Y, %H:%M",
          })}
        </CardField>
      )}

      <AutoCommissionEnrollmentSource
        source={auto_commission_payout_enrollment_source}
      />
    </Card>
  );
}