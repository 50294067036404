import { makeRequest } from "./utils";

////
// Creators
//
export const getCreators = (params) => makeRequest({
  method: "get",
  url: '/api/admin/creators',
  params,
});

export const getCreator = (creator_id) => makeRequest({
  method: "get",
  url: `/api/admin/creators/${creator_id}`,
});

export const updateCreatorWaitlist = ({creator_id, status}) => makeRequest({
  method: "put",
  url: `/api/admin/creators/${creator_id}/waitlist`,
  data: {status},
});
