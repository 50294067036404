import React, { useState, useEffect } from "react";
import { getProductCategories } from "../../../services/api";
import { Box, Spinner, InlineError, OptionList } from '@shopify/polaris';

import { GENERIC_ERROR_MESSAGE } from "../../../constants";

const CategorySelect = ({ onChange, initialCheckedValues }) => {
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(
    initialCheckedValues || []
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    getProductCategories()
      .then((categories) => {
        const optionsArray = [];

        if (categories.length) {
          categories.map((option) => 
            optionsArray.push({ value: option.key, label: option.name })
          );
          setOptions(optionsArray);
        }
      })
      .catch(error => {
        setError(error.msg || GENERIC_ERROR_MESSAGE)
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions, onChange]);

  return ( 
    <Box padding="0">
      { error && <InlineError message={error} /> }

      {!error && isLoading && (
        <Spinner accessibilityLabel="Data is loading" size="small" />
      )}

      {!error && !isLoading && options.length && (
        <OptionList
          allowMultiple
          title="Product categories"
          onChange={setSelectedOptions}
          options={options}
          selected={selectedOptions}
        />
      )}
    </Box>
  );
};

export default CategorySelect;
