import { makeRequest } from "./utils";

////
// Customers
//
export const getCustomers = (params) =>
  makeRequest({
    method: "get",
    url: "/api/admin/customers",
    params,
  });

export const getCustomer = ({ customer_id, ...params }) =>
  makeRequest({
    method: "get",
    url: `/api/admin/customers/${customer_id}`,
    params,
  });

export const updateCustomerLimits = ({ customer_id, ...data }) =>
  makeRequest({
    method: "put",
    url: `/api/admin/customers/${customer_id}/limits`,
    data,
  });
