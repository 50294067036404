import React from 'react';

import {
  Box,
  InlineStack,
  Text,
} from '@shopify/polaris';

export const CardField = ({ title, children }) => {
  return (
    <Box paddingBlockStart="300">
      <InlineStack gap="300" align="space-between">
        <Text as="p" fontWeight="strong" tone="subdued">
          {title}
        </Text>
        <Text as="p" variant="bodyMd">
          {children}
        </Text>
      </InlineStack>
    </Box>
  );
};
