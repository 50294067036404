import React from 'react';

import {
  Box,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { ReactComponent as CollabsIcon } from '../../images/collabs-icon.svg';

export default function Logo() {
  return (
    <Box paddingBlock="600">
      <InlineStack gap="400" align="center" blockAlign="center">
        <CollabsIcon />
        <Text as="h1" variant="headingMd" tone="text-inverse">Shopify Collabs</Text>
      </InlineStack>
    </Box>
  )
}
