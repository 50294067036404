import React, { useEffect, useState } from "react";

import { 
  Page,
  BlockStack,
  DataTable,
  InlineError,
  Card,
  Box,
  EmptyState,
  TextField,
  Icon,
 } from "@shopify/polaris";
 import {SearchIcon} from '@shopify/polaris-icons';

import { formatDate } from "../../services/utils";
import { getFeedback } from "../../services/api";
import PageSpinner from "../../components/page_spinner/PageSpinner";
import useDebouncedInput from "../../hooks/useDebouncedInput";


import styles from './Feedback.module.css';

import { GENERIC_ERROR_MESSAGE, images } from "../../constants";

export default function Feedback() {
  const [isLoading, setIsLoading] = useState(true);
  const [feedback, setFeedback] = useState([]);
  const [cursor, setCursor] = useState(null);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebouncedInput(search, 300);
  const [error, setError] = useState(null);

  const getData = (params) => {
    setIsLoading(true);
    setError(null);
    getFeedback(params).then((res) => {
      res.items && setFeedback(res.items);
      setCursor(res.cursor);
    }).catch(error => {
      setError(error.msg || GENERIC_ERROR_MESSAGE)
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    getData({});
  }, []);

  useEffect(() => {
    setCursor(null);
    const params = {cursor: null};
    debouncedSearch && (params.shopify_store_id = debouncedSearch);
    getData(params);
  }, [debouncedSearch]);

  const mergeById = (arr1, arr2) => {
    const map = new Map();
  
    arr1.forEach(item => map.set(item.id, item));
    arr2.forEach(item => map.set(item.id, { ...map.get(item.id), ...item }));
    return Array.from(map.values());
  };

  const handlePageChange = () => {
    if (cursor) {
      setError(null);
      setIsLoading(true);
      const params = {cursor};
      debouncedSearch && (params.shopify_store_id = debouncedSearch);

      getFeedback(params)
        .then(res => {
          res.items && setFeedback(mergeById(feedback, res.items));
          setCursor(res.cursor);
        })
        .catch(error => {
          setError(error.msg || GENERIC_ERROR_MESSAGE)
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const rowMarkup = feedback.map((item) => {
    return [
      item.id,
      <p className={styles.FeedbackCell}>{item.feedback}</p>,
      formatDate({
        value: new Date(item.created_at),
        strftime: "%b %d, %Y",
      }),
      item.merchant.name,
      item.merchant.shopify_store_id,
      item.merchant.type,
    ];
   });

   const emptyStateMarkup = (
    <EmptyState
      heading="No Feedback yet"
      image={images.emptyStateImage}
    />
  );

  return (
    <Page
      fullWidth
      title="Feedback from merchants on Collabs App"
    >
      <Box paddingBlockEnd="1200">
        <BlockStack gap="500" align="space-around">

          <Box maxWidth="50%">
            <TextField
              value={search}
              onChange={(value) => setSearch(value)}
              prefix={<Icon source={SearchIcon} />} />
          </Box>

          {error && <InlineError message={error} />}

          {!error && isLoading && (
            <PageSpinner />
          )}

          {!error && !isLoading && !Boolean(feedback.length) && (
            <Card>
              {emptyStateMarkup}
            </Card>
          )}

          {!error && !isLoading && Boolean(feedback.length)  && (
            <Card padding="0">
              <DataTable
                itemCount={feedback.length}
                columnContentTypes={[
                  'text',
                  'text',
                  'text',
                  'text',
                  'numeric',
                  'numeric',
                ]}
                truncate={false}
                stickyHeader
                verticalAlign="middle"
                pagination={{
                  type: "table",
                  hasNext: cursor,
                  onNext: handlePageChange,
                }}
                headings={[
                  'ID',
                  'Feedback',
                  'Date',
                  'Merchant Name',
                  'Merchant Store ID',
                  'Merchant type',
                ]}
                rows={rowMarkup}
              >
              </DataTable>
            </Card>
          )} 
        </BlockStack>
      </Box>
    </Page>
  );
}
