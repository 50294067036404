import React from 'react';
import {
  InlineStack,
  Text,
  Box,
} from '@shopify/polaris';

export const CardHeader = ({ title, children }) => {
  return (
    <Box paddingBlock="100">
      <InlineStack gap="300" align="space-between">
        <Text as="h2" variant="headingSm">
          {title}
        </Text>
        {children}
      </InlineStack>
    </Box>
  );
};
