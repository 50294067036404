import { Badge } from "@shopify/polaris";

export default function CollectionStatus({ status }) {
  const BADGE_COLORS = {
    draft: "attention",
    active: "success",
  };

  return (
    status && (
    <Badge size="large" tone={BADGE_COLORS[status]}>
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </Badge>
    )
  );
}
