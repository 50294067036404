import React, { useEffect, useState } from "react";
import {
  Page,
  IndexTable,
  InlineError,
  Card,
  useBreakpoints,
  Box,
  Button,
  ButtonGroup,
  BlockStack,
  Layout,
  Text,
} from '@shopify/polaris';
import { CardField } from '../../components/card';
import Modal from '../../components/modal/Modal';
import { useParams } from "react-router-dom"; 

import { getCommissionsByCreator, approveCommissionsPayout, rejectCommissionsPayout } from "../../services/api/commissions"; 
import { GENERIC_ERROR_MESSAGE, paths } from "../../constants";

import PageSpinner from "../../components/page_spinner/PageSpinner";

import styles from './CommissionsTable.module.css';

export default function CreatorCommissionsTable() {
  const { creatorId } = useParams(); 
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [creator, setCreator] = useState(null);
  const [commissionsAmountSummary, setCommissionsAmountSummary] = useState({});
  const [error, setError] = useState(null);
  const [actionPerformed, setActionPerformed] = useState(false);
  const [actionConfirmModalActive, setActionConfirmModalActive] = useState(false);
  const [payoutAction, setPayoutAction] = useState("");

  const resourceName = {
    singular: 'commission',
    plural: 'commissions',
  };

  const { breakpoint } = useBreakpoints();

  useEffect(() => {
    setError(null);

    getCommissionsByCreator(creatorId)
      .then((res) => {
        setCreator(res.creator);
        setCommissionsAmountSummary(res.commissions_amount_summary || {}); 
      })
      .catch(error => {
        setError(error.msg || GENERIC_ERROR_MESSAGE);
        setCommissionsAmountSummary({}); 
      })
      .finally(() => {
        setIsPageLoading(false);
      });
  }, [creatorId]);

  const handlePayoutActionClick = (action) => {
    setPayoutAction(action);
    setActionConfirmModalActive(true);
  }

  const handlePayoutActionConfirm = () => {
    switch(payoutAction) {
      case 'approve':
        approveCommissionsPayout(creator?.id).then((res) => {
        }).catch(error => {
          setError(error.msg || GENERIC_ERROR_MESSAGE);
        });
        break;
      case 'reject':
        rejectCommissionsPayout(creator?.id).then((res) => {
        }).catch(error => {
          setError(error.msg || GENERIC_ERROR_MESSAGE);
        });
        break;
      default:
        break;
    }
    setActionPerformed(true);
    setPayoutAction('');
    setActionConfirmModalActive(false)
  }

  const handlePayoutActionConfirmModalClose = () => {
    setActionPerformed(false);
    setPayoutAction('');
    setActionConfirmModalActive(false)
  }

  const payoutActionConfirmModal = actionConfirmModalActive ? (
    <Modal
      title="Confirm Payout Action"
      isOpen={actionConfirmModalActive}
      onClose={handlePayoutActionConfirmModalClose}
      onAction={handlePayoutActionConfirm}
      buttonTitle="Confirm"
    >
      <Text
        variant="bodyLg" as="p" alignment="justify"
      >
        Do you want to proceed with the payout action?
      </Text>
    </Modal>
  ) : null;
  
  const payoutActions = (
    <ButtonGroup>
      <Button variant="primary" tone='success' disabled={actionPerformed} onClick={() => handlePayoutActionClick("approve")} >{actionPerformed ? `Payout Approved` : `Approve Payout`}</Button>
      <Button variant="primary" tone='critical' disabled={actionPerformed} onClick={() => handlePayoutActionClick("reject")} >{actionPerformed ? `Payout Rejected` : `Reject Payout`}</Button>
    </ButtonGroup>
  );

  // separate row for ready_for_payment to make sure that it is on top of the table
  const readyForPaymentRowMarkup = commissionsAmountSummary.ready_for_payment ? (
    <IndexTable.Row
      id={'ready_for_payment'}
      key={'ready_for_payment'}
      position={0}
    >
      <IndexTable.Cell className={styles.TableCell}>{'ready_for_payment'}</IndexTable.Cell>
      <IndexTable.Cell className={styles.TableCell}>${(commissionsAmountSummary.ready_for_payment/100).toFixed(2)}</IndexTable.Cell>
      <IndexTable.Cell className={styles.TableCell}>{payoutActions}</IndexTable.Cell>
    </IndexTable.Row>
  ) : null;
  
  const rowMarkup = Object.keys(commissionsAmountSummary).filter((key) => key !== "ready_for_payment").map((status, index) => (
    <IndexTable.Row
      id={status}
      key={status}
      position={index + 1}
    >
      <IndexTable.Cell className={styles.TableCell}>{status}</IndexTable.Cell>
      <IndexTable.Cell className={styles.TableCell}>${(commissionsAmountSummary[status]/100).toFixed(2)}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page
      fullWidth
      title="Creator Commissions"
      backAction={{ content: '', url: paths.admin.commissions }}
    >
      <Box paddingBlockEnd="1200">
        {error && <InlineError message={error} />}

        {!error && !commissionsAmountSummary.size && isPageLoading && (
          <PageSpinner />
        )}

        {!error && !isPageLoading && (
          <BlockStack gap="500" align="space-around">

            <Box paddingBlockStart="4">
              <BlockStack spacing="tight">
                <Text variant="headingMd">Creator Profile</Text>
              </BlockStack>
            </Box>

            {creator && (
              <Layout>
                <Layout.Section variant="oneHalf">
                  <Card>
                    <CardField title='Creator ID'>{creator.id}</CardField>
                    <CardField title='Creator Name'>{creator.name}</CardField>
                    <CardField title='Creator Type'>{creator.owner_type}</CardField>
                    <CardField title='Creator Active'>{creator.active.toString()}</CardField>
                  </Card>
                </Layout.Section>
                <Layout.Section>
                  <BlockStack gap="500" />
                </Layout.Section>
              </Layout>
            )}
            

            <Box paddingBlockStart="4">
              <BlockStack spacing="tight">
                <Text variant="headingMd">Commissions Overview</Text>
              </BlockStack>
            </Box>

            <Card padding="0">
              <IndexTable
                condensed={breakpoint?.smDown}
                resourceName={resourceName}
                itemCount={Object.keys(commissionsAmountSummary).length}
                selectable={false}
                headings={[
                  { title: 'Status' },
                  { title: 'Total Commission Amount (USD)' },
                  { title: 'Action' },
                ]}
              >
                {readyForPaymentRowMarkup}
                {rowMarkup}
              </IndexTable>
            </Card>
          </BlockStack>
        )}
      </Box>
      {payoutActionConfirmModal}
    </Page>
  );
}