import React from 'react';
import { createPortal } from "react-dom";

import {
  BlockStack,
  Card,
  InlineGrid,
  Text,
  Button,
  InlineStack,
  ButtonGroup,
} from '@shopify/polaris';

import styles from './Modal.module.css';

export default function Modal({
  children,
  title,
  isOpen = false,
  onClose,
  onAction,
  loading,
  buttonTitle,
  actionDisabled,
}) {

  if (!isOpen) {
    return null;
  }

  return (
    <>
      {createPortal(
        <div className={styles.ModalOverlay}>
          <Card>
            <BlockStack gap="400">
              <InlineGrid columns="1fr auto">
                <Text as="h2" variant="headingLg">
                  {title}
                </Text>
                <Button
                  variant="plain"
                  onClick={onClose}
                  accessibilityLabel="Close modal"
                >
                 X Close
                </Button>
              </InlineGrid>
              <div className={styles.ModalContent}>
                {children}
              </div>
              <InlineStack align="end">
                <ButtonGroup>
                  <Button
                    variant="secondary"
                    onClick={onClose}
                    accessibilityLabel="Close modal"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={onAction}
                    loading={loading}
                    disabled={actionDisabled}
                    accessibilityLabel={title}
                  >
                    {buttonTitle}
                  </Button>
                </ButtonGroup>
              </InlineStack>
            </BlockStack>
          </Card>
        </div>,
        document.querySelector("body")
      )}
    </>
  );
}
