/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  Page, 
  BlockStack,
  EmptyState,
  InlineError,
  Card,
  Box,
} from '@shopify/polaris';

import {getProductCategories, getCuratedCollection, removeCuratedCollection } from "../../../services/api";
import PageSpinner from "../../../components/page_spinner/PageSpinner";
import EditCuratedCollectionModal from "./components/EditCuratedCollectionModal";
import AddProductModal from "./components/AddProductModal";

import { GENERIC_ERROR_MESSAGE, paths, images } from "../../../constants";

import CuratedCollectionHeader from "./components/CuratedCollectionHeader";
import CollectionProductsList from "./components/CollectionProductsList";

export default function CuratedCollection() {
  const [editCuratedCollectionModal, setEditCuratedCollectionModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [collection, setCollection] = useState({});
  const [productCategories, setProductCategories] = useState({});
  const { collectionId } = useParams();
  const [isProductCategoriesLoading, setIsProductCategoriesLoading] = useState(true);
  const [isCuratedCollectionLoading, setIsCuratedCollectionLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getProductCategories()
    .then((res) => {
      let categoriesMap = {};

      res.forEach((category) => {
        categoriesMap[category.key] = category.name;
      });

      setProductCategories(categoriesMap);
    })
    .catch((err) => {
      setError(err.msg || GENERIC_ERROR_MESSAGE);
    })
    .finally(() => {
      setIsProductCategoriesLoading(false);
    });

    getCuratedCollection(collectionId)
    .then((res) => setCollection(res))
    .catch((err) => {
      setError(err.msg || GENERIC_ERROR_MESSAGE);
    })
    .finally(() => {
      setIsCuratedCollectionLoading(false);
    });
  }, []);

  const deleteCollection = () => {
    setError(null);

    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Are you sure you'd like to remove this collection?`
      )
    ) {
      setIsCuratedCollectionLoading(true);

      removeCuratedCollection(collectionId)
      .then(() => {
        navigate(paths.admin.curatedCollections);
      })
      .catch((err) => {
        setError(err.msg || GENERIC_ERROR_MESSAGE);
      })
      .finally(() => {
        setIsCuratedCollectionLoading(false);
      });
    }
  };

  return (
    <Page
      backAction={{content: '', url: paths.admin.curatedCollections}}
      title="Back to all collections"
      fullWidth
    >
      <BlockStack gap="500" align="space-around">

        {Boolean(collection) && <EditCuratedCollectionModal
            isModalOpen={editCuratedCollectionModal}
            curratedCollection={collection}
            onClose={() => setEditCuratedCollectionModal(false)}
          />
        }

        {Boolean(collection) && <AddProductModal
            isModalOpen={showProductModal}
            collectionId={collectionId}
            onClose={() => setShowProductModal(false)}
          />
        }

        {error && <InlineError message={error} />}

        {!error && isProductCategoriesLoading && isCuratedCollectionLoading && (
          <PageSpinner />
        )}

        {!error && !(isProductCategoriesLoading || isCuratedCollectionLoading) && !Boolean(collection) && (
          <EmptyState
            heading="No collection"
            image={images.emptyStateImage}
          />
        )}

        {!error && !isCuratedCollectionLoading && Boolean(collection) && (
          <CuratedCollectionHeader
            collection={collection} 
            deleteCollection={deleteCollection}
            openEditCuratedCollectionModal={() => setEditCuratedCollectionModal(true)}
            openAddProductModal={() => setShowProductModal(true)}
            productCategories={productCategories}
          /> 
        )}

        {!error && ((isProductCategoriesLoading && !isCuratedCollectionLoading) || (!isProductCategoriesLoading && isCuratedCollectionLoading)) && Boolean(collection) && (
          <Card>
            <PageSpinner />
          </Card>
        )}
       
        {!error && !isProductCategoriesLoading && Boolean(collection) && (
          <Box paddingBlockEnd="400">
            <CollectionProductsList collectionId={collectionId} />
          </Box>
        )}
      </BlockStack>
    </Page>
  );
}
