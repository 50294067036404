/* eslint-disable no-unused-vars */

import React, { useContext, Suspense, lazy } from 'react';
import { Box, BlockStack, SkeletonPage, Text, Icon, InlineStack } from '@shopify/polaris';
import { UserContext } from './context/UserContext';
import Logo from './apps/components/Logo';
import {ExternalIcon, PersonFilledIcon, LinkIcon} from '@shopify/polaris-icons';

import PageSpinner from './components/page_spinner/PageSpinner';
import UnauthorizedPage from './pages/auth/UnauthtorizedPage';

import styles from './App.module.css';

const Admin = lazy(() => import('./apps/Admin'));

export default function App() {
  const { user, pageIsLoading } = useContext(UserContext);

  if (pageIsLoading) return <PageSpinner />;

  if (!pageIsLoading && user === undefined) return <UnauthorizedPage />;

  if (!pageIsLoading && user) {
    return (
      <Suspense fallback={<Loading />}>
        <div className={styles.PageWrapper}>
          <Admin />
        </div>
      </Suspense>
    );
  }
}

const navigationItemsData = [
  {
    title: 'Merchants',
    icon: PersonFilledIcon,
  },
  {
    title: 'Creators',
    icon: PersonFilledIcon,
  },
  {
    title: 'Products',
    icon: PersonFilledIcon,
  },
  {
    title: 'Curated Collections',
    icon: PersonFilledIcon,
  },
  {
    title: 'Diagnostics Tools',
    icon: PersonFilledIcon,
  },

  {
    title: 'Feedback',
    icon: PersonFilledIcon,
  },
  {
    title: 'Sidekiq Api',
    icon: LinkIcon,
  },
  {
    title: 'Sidekiq Analytics',
    icon: LinkIcon,
  },
  {
    title: 'Flipper UI',
    icon: LinkIcon,
  },
];



function Loading() {

  const navigationItems = Array.from(navigationItemsData).map(({title, icon}, index) =>
    <div className={styles.NavigationItem} key={`loading-menu-${index}`}>
      <InlineStack align="space-evenly" gap="100">
        <Icon source={icon} tone="subdued" />
        <Text tone="subdued" as="strong">{title}</Text>
      </InlineStack>
    </div>
  );

  return (
    <div className={styles.PageWrapper}>
      <InlineStack  gap="0">
          <div className={styles.Navigation}>
            <BlockStack align="start">
              <Box>
                <Logo />
              </Box>
              {navigationItems}
            </BlockStack>
              
            <div className={styles.NavigationItemLast} key={`loading-log-out`}>
              <InlineStack align="space-evenly" gap="100">
                <Icon source={ExternalIcon} tone="subdued" />
                <Text tone="subdued" as="strong">Sign out</Text>
              </InlineStack>
            </div>
          </div>
     
          <div className={styles.Content}>
            <SkeletonPage fullWidth>
              <Box paddingBlockEnd="1200">
                <BlockStack gap="500" align="space-around">
                  <PageSpinner />
                </BlockStack>
              </Box>
            </SkeletonPage>
          </div>
        </InlineStack>
    </div>
  );
}
