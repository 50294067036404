import React, { useEffect, useState } from "react";

import {
  Page,
  TextField,
  Link,
  BlockStack,
  IndexTable,
  Icon,
  InlineError,
  Card,
  Badge,
  Avatar,
  useBreakpoints,
  useIndexResourceState,
  IndexFilters,
  useSetIndexFiltersMode,
  EmptySearchResult,
  Box,
} from '@shopify/polaris';
import {SearchIcon} from '@shopify/polaris-icons';

import { getProducts, updateProductsVisibility } from "../../services/api";
import { GENERIC_ERROR_MESSAGE } from "../../constants";

import useDebouncedInput from "../../hooks/useDebouncedInput";
import PageSpinner from "../../components/page_spinner/PageSpinner";

import styles from './ProductsTable.module.css';

const TOKEN_COLORS = {
  true: "success",
  false: "warning",
};

export default function ProductsTable() {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFilterChanging, setIsFilterChanging] = useState(false);
  const [products, setProducts] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebouncedInput(search, 300);
  const [onlyHidden, setOnlyHidden] = useState(false);
  const [onlyNetwork, setOnlyNetwork] = useState(false);
  const [error, setError] = useState(null);
  const {mode, setMode} = useSetIndexFiltersMode();
  const [currentTab, setCurrentTab] = useState(0);

  const {selectedResources, handleSelectionChange, clearSelection} = useIndexResourceState(products);

  const resourceName = {
    singular: 'product',
    plural: 'products',
  };

  const {breakpoint} = useBreakpoints();

  useEffect(() => {
    setError(null);

    getProducts({
        page: currentPage,
        query: debouncedSearch,
        only_hidden: onlyHidden,
        only_network: onlyNetwork,
      })
      .then((res) => {
        setProducts(res.products);
        setRowCount(res.total_count);
        setHasNextPage(res.has_next_page);
      })
      .catch(error => {
        setError(error.msg || GENERIC_ERROR_MESSAGE)
      })
      .finally(() => {
        setIsFilterChanging(false);
        setIsPageLoading(false);
      });
  }, [debouncedSearch, onlyHidden, onlyNetwork, currentPage]);

  const handleVisibilityUpdate = (visible) => {
    setIsFilterChanging(true);
    setError(null);

    updateProductsVisibility({ product_ids: selectedResources, visible: visible })
      .then(() => {
        getProducts({
            page: currentPage,
            query: debouncedSearch,
            only_hidden: onlyHidden,
            only_network: onlyNetwork,
          })
          .then((res) => {
            setProducts(res.products);
            setRowCount(res.total_count);
            setHasNextPage(res.has_next_page);
            handleSelectionChange('type', false);
          })
          .catch(error => {
            setError(error.msg || GENERIC_ERROR_MESSAGE)
          });
      })
      .catch(error => {
        setError(error.msg || GENERIC_ERROR_MESSAGE)
      })
      .finally(() => {
        setIsFilterChanging(false);
      });
  };

  const handleMarkProductsAsHidden = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you'd like to hide ${selectedResources.length} product(s) from the Collabs Network?`)) {
      handleVisibilityUpdate(false);
    }
  };

  const handleMarkProductsAsVisible = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you'd like to make ${selectedResources.length} product(s) visible to the Collabs Network?`)) {
      handleVisibilityUpdate(true);
    }
  };

  const rowMarkup = products.map((product, index) => (
    <IndexTable.Row
      id={product.id}
      key={product.id}
      selected={selectedResources.includes(product.id)}
      position={index}
    >
      <IndexTable.Cell className={styles.TableCell}>
        <Avatar 
          key={`image-${product.id}`}
          source={product.image_url}
          size="xl"
          name={product.title}
        />
      </IndexTable.Cell>
      <IndexTable.Cell className={styles.TableCell}>{product.shopify_product_id}</IndexTable.Cell>
      <IndexTable.Cell className={styles.TableCell}>{product.title}</IndexTable.Cell>
      <IndexTable.Cell className={styles.TableCell}>
        <Link 
          target="_blank"
          dataPrimaryLink
          key={`store-of-product-${product.id}`}
          url={`https://${product.shopify_store.domain}`}
        >
          {product.shopify_store.name}
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell className={styles.TableCell}>
        <Badge size="large" tone={TOKEN_COLORS[product.network_available]}>
          {product.network_available ? "Yes" : "No"}
        </Badge>
      </IndexTable.Cell>
      <IndexTable.Cell className={styles.TableCell}>
        {product.network_commission ? product.network_commission + '%' : '—'}
      </IndexTable.Cell>
      <IndexTable.Cell className={styles.TableCell}>
        <Badge size="large" tone={TOKEN_COLORS[product.hidden]}>
          {product.hidden ? "Yes" : "No"}
        </Badge>
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  const promotedBulkActions = [
    {
      content: 'Hidden from CN',
      onAction: () => handleMarkProductsAsHidden(),
    },
    {
      content: 'Visible in CN',
      onAction: () => handleMarkProductsAsVisible(),
    },
  ];

  const handleSelection = (type, selected, id, position) => {
    if (selectedResources.length && type === 'page') {
      clearSelection();
    } else {
      handleSelectionChange(type, selected, id, position);
    }
  }

  const tabs = [
    {
      id: 'all-products',
      content: 'All',
      tabIndexOverride: 0,
      onAction: () =>  {
        setIsFilterChanging(true);
        setCurrentTab(0);
        setOnlyNetwork(false);
        setOnlyHidden(false);
      },
      selected: true,
    },
    {
      id: 'only-hidden-products',
      content: 'Only hidden products',
      tabIndexOverride: 0,
      onAction: () => {
        setIsFilterChanging(true);
        setCurrentTab(1);
        setOnlyNetwork(false);
        setOnlyHidden(true);
      },
    },
    {
      id: 'only-network-products',
      content: 'Only network products',
      tabIndexOverride: 0,
      onAction: () => {
        setIsFilterChanging(true);
        setCurrentTab(2);
        setOnlyNetwork(true);
        setOnlyHidden(false);
      },
    },
  ];

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No products yet'}
      description={'Try changing the filters or search term'}
      withIllustration
    />
  );

  return (
    <Page
      fullWidth
      title="Products"
    >
      <Box paddingBlockEnd="1200">
        <BlockStack gap="500" align="space-around">

          <Box maxWidth="50%">
            <TextField
              value={search}
              onChange={(value) => setSearch(value)}
              prefix={<Icon source={SearchIcon} />} />
          </Box>

          {error && <InlineError message={error} />}

          {!error && !products.length && isPageLoading && (
            <PageSpinner />
          )}

          {!error && !isPageLoading && (
            <Card padding="0">
              <IndexFilters
                tabs={tabs}
                mode={mode}
                setMode={setMode}
                canCreateNewView={false}
                hideFilters
                hideQueryField
                selected={currentTab}
                loading={isFilterChanging}
              />
              <IndexTable
                condensed={breakpoint?.smDown}
                resourceName={resourceName}
                promotedBulkActions={promotedBulkActions}
                itemCount={rowCount}
                selectedItemsCount={selectedResources.length}
                onSelectionChange={(type, selected, id, position) => handleSelection(type, selected, id, position)}
                headings={[
                  {title: ''},
                  {title: 'Shopify ID'},
                  {title: 'Title'},
                  {title: 'Store'},
                  {title: 'Network'},
                  {title: 'Network commission'},
                  {title: 'Hidden'},
                ]}
                pagination={{
                  hasNext: hasNextPage,
                  hasPrevious: currentPage > 1,
                  onNext: () => setCurrentPage(currentPage + 1),
                  onPrevious: () => setCurrentPage(currentPage - 1),
                }}
                emptyState={emptyStateMarkup}
              >
                {rowMarkup}
              </IndexTable>
            </Card>
          )}
        </BlockStack>
      </Box>
    </Page>
  );
}
