import React, { useState } from "react";

import { 
  BlockStack,
  TextField,
  InlineError, 
  Box,
} from '@shopify/polaris';

import { addProductToCuratedCollection } from "../../../../services/api";
import { GENERIC_ERROR_MESSAGE } from "../../../../constants";

import Modal from '../../../../components/modal/Modal';

export default function AddProductModal({ collectionId, onClose, isModalOpen, }) {
  const [error, setError] = useState(null);
  const [productError, setProductError] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [productId, setProductId] = useState("");

  const handleCreate = () => {
    setError(null);
    setProductError(null);

    if (!productId) {
      setProductError("Product ID cannot be blank");
      return;
    }

    setIsCreating(true);

    addProductToCuratedCollection(collectionId, {
        shopify_product_id: productId,
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        setError(err.msg?.message || GENERIC_ERROR_MESSAGE);
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  const handleClose = () => {
    setError(null);
    setProductError(null);
    setProductId("");
    setIsCreating(false);
    onClose();
  }

  return (
    <Modal
      title="Add Product"
      isOpen={isModalOpen}
      onClose={handleClose}
      onAction={handleCreate}
      loading={isCreating}
      buttonTitle="Update"
    >
      <Box padding="200">
        <BlockStack gap="400">

          { error && <InlineError message={error} /> }

          <TextField
            label="Shopify Product ID"
            value={productId}
            onChange={(value) => setProductId(value)}
            autoComplete="off"
            error={productError}
          />

        </BlockStack>
      </Box>
    </Modal>
  );
}
