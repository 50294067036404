import React, { useState, useEffect } from 'react';
import * as api from '../services/api';

export const UserContext = React.createContext();

export default function UserProvider({ children }) {
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [user, setUser] = useState();

  useEffect(() => {
    api
        .getCurrentUser()
        .then((res) => setUser(res))
        .catch((err) => setUser(null))
        .finally(() => setPageIsLoading(false));
  }, []);

  return (
    <UserContext.Provider value={{ user, pageIsLoading }}>
      {children}
    </UserContext.Provider>
  );
}
