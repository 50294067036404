/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from "react";

import {
  Card,
  Box,
  Button,
} from '@shopify/polaris';
import { EditIcon } from '@shopify/polaris-icons';

import { CardHeader, CardField } from "../../../../components/card";
import EditLimitsModal from "./EditLimitsModal";


export default function MerchantSubscription({ merchant, setMerchant }) {
  const [editLimits, setEditLimits] = useState(false);

  return (
    <>
    <Card>
      <CardHeader title="Products" />

      <EditLimitsModal
        isModalOpen={editLimits}
        customer={merchant}
        onCancel={() => setEditLimits(false)}
        onUpdate={(limits) => {
          setEditLimits(false);
          setMerchant((curr) => ({
            ...curr,
            plan_limits: limits,
          }));
        }}
      />

      <Box paddingBlock="400">
        {!Object.keys(merchant.subscription.subscription_items).length && (
          <CardField title="None" />
        )}
        {Object.keys(merchant.subscription.subscription_items).map((item) => (
          <CardField title={item}>{item.amount || "-"}</CardField>
        ))}
      </Box>
    </Card>
    <Box paddingBlockEnd="400">
      <Card>
        <CardHeader title="Limits">
          <Button icon={EditIcon} variant="primary" size="medium" onClick={() => setEditLimits(true)}>
            Edit
          </Button>
        </CardHeader>
        <CardField title="List Row Count">
          {merchant.plan_limits.list_row_count} /{" "}
          {merchant.plan_limits.list_row_limit}
        </CardField>
        <CardField title="List Row Delta">
          {merchant.plan_limits.list_row_delta}
        </CardField>
        <CardField title="Community Invitation 7 day limit">
          {merchant.plan_limits.community_invitation_7_day_limit ||
            "unlimited"}
        </CardField>
      </Card>
    </Box>
  </>
  );
}
