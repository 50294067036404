/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";

import {
  InlineError,
  EmptyState,
  Avatar,
  Card,
  DataTable,
  Button,
} from '@shopify/polaris';
import { DeleteIcon } from '@shopify/polaris-icons';

import { GENERIC_ERROR_MESSAGE, images } from "../../../../constants";
import { getCuratedCollectionProducts, removeProductFromCuratedCollection } from "../../../../services/api";
import PageSpinner from "../../../../components/page_spinner/PageSpinner";

import NetworkAvailableBadge from "./NetworkAvailableBadge";

export default function CollectionProductsList({ collectionId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);

  const fetchProducts = () => {
    setIsLoading(true);
    setError(null);

    getCuratedCollectionProducts(collectionId)
      .then((res) => {
        setProducts(res);
      })
      .catch((err) => {
        setError(err.msg || GENERIC_ERROR_MESSAGE);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const executeProductRemoval = (productId) => {
    setIsLoading(true);
    setError(null);

    removeProductFromCuratedCollection(collectionId, productId)
    .then(() => {
      fetchProducts();
    })
    .catch((err) => {
      setError(err.msg || GENERIC_ERROR_MESSAGE);
      setIsLoading(false);
    });
  };

  const removeProduct = (productId) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Are you sure you'd like to remove product from this collection?`
      )
    ) {
      executeProductRemoval(productId);
    }
  };

  const rows = products.map((product) => [
      <a href={product.image_url} target="_blank" rel="noreferrer">
        <Avatar
          source={product.image_url}
          size="xl"
          name={product.title}
        />
      </a>,
      product.id,
      product.title,
      product.shopify_store.name,
      <NetworkAvailableBadge networkAvailable={product.network_available} />,
      <Button 
        variant="secondary"
        size="medium"
        icon={DeleteIcon}
        tone="critical"
        onClick={() => removeProduct(product.id)}
      >
        Remove 
      </Button>
    ])

  return (
    <Card>
        {error && <InlineError message={error} />}

        {!error && isLoading && (
          <PageSpinner />
        )}

        {!error && !isLoading && !Boolean(products.length) && (
          <EmptyState
            heading="No products yet"
            image={images.emptyStateImage}
          >
            Try adding products to this collection.
          </EmptyState>
        )}

        {!error && !isLoading && Boolean(products.length) && ( 
          <DataTable
            columnContentTypes={[
              'text',
              'text',
              'text',
              'text',
              'text',
              'numeric',
            ]}
            verticalAlign="middle"
            headings={[
              '',
              'ID',
              'Title',
              'Brand',
              'In CN?',
              'Actions',
            ]}
            rows={rows}
          />
        )}
    </Card>
  );
}
