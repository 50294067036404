import React, { useEffect, useState, useCallback } from "react";

import { 
  BlockStack,
  TextField,
  InlineError, 
  Box,
  ChoiceList,
} from '@shopify/polaris';

import { updateCuratedCollection } from "../../../../services/api";
import { GENERIC_ERROR_MESSAGE } from "../../../../constants";

import Modal from '../../../../components/modal/Modal';
import CategorySelect from "../../common/CategorySelect";

export default function EditCuratedCollectionModal({
  curratedCollection={},
  onClose,
  isModalOpen
}) {
  const [error, setError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [collection, setCollection] = useState(curratedCollection);
  const [titleError, setTitleError] = useState(null);

  const handleChangeTitle = (value) => {
    setTitleError(null);
    setCollection((curr) => ({ ...curr, title: value }));
  };

  const handleChangeStatus = (value) => {
    setCollection((curr) => ({ ...curr, status: value[0] }));
  };

  const handleChangeDescription = (value) => {
    setCollection((curr) => ({ ...curr, description: value }));
  };

  const handleChangeCategories = useCallback((value) => {
    setCollection((curr) => ({ ...curr, categories: value }));
  }, []);

  const handleEdit = () => {
    setError(null);
    setTitleError(null);

    if (!collection.title || !collection.status) {
      !collection.title && setTitleError("Title cannot be blank");
      !collection.status && setError("Status cannot be blank");
      return;
    }

    setIsSaving(true);

    updateCuratedCollection(collection.id, { collection: { ...collection } })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        setError(err.msg || GENERIC_ERROR_MESSAGE);
        setIsSaving(false);
      });
  };

  useEffect(() => {
    setCollection(curratedCollection);
  }, [curratedCollection]);

  const handleClose = () => {
    setCollection({});
    setError(null);
    setTitleError(null)
    setIsSaving(false);
    onClose();
  }

  return (
    <Modal
      title="Edit Collection"
      isOpen={isModalOpen}
      onClose={handleClose}
      onAction={handleEdit}
      loading={isSaving}
      buttonTitle="Update"
    >
      <Box padding="200">
        { collection && (
          <BlockStack gap="400">

            { error && <InlineError message={error} /> }

            <ChoiceList
              title="Status"
              choices={[
                {label: 'Draft', value: 'draft'},
                {label: 'Active', value: 'active'},
              ]}
              selected={collection.status}
              onChange={handleChangeStatus}
            />

            <TextField
              label="Title"
              value={collection.title}
              onChange={handleChangeTitle}
              autoComplete="off"
              error={titleError && "Title can not be blank"}
            />

            <TextField
              label="Description"
              value={collection.description}
              onChange={handleChangeDescription}
              multiline={5}
              autoSize
              autoComplete="off"
            />

            <CategorySelect
              initialCheckedValues={collection.categories}
              onChange={handleChangeCategories} />
          </BlockStack>
        )}
      </Box>
    </Modal>
  );
}
