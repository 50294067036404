import React, { useState, useCallback } from 'react';
import { Text, TextField, InlineError, FormLayout, Box } from '@shopify/polaris';
import { updateCustomerLimits } from '../../../../services/api';

import Modal from '../../../../components/modal/Modal';

import { GENERIC_ERROR_MESSAGE } from "../../../../constants";

export default function EditLimitsModal({ customer, onCancel, onUpdate, isModalOpen }) {
  const [limits, setLimits] = useState(customer.plan_limits);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const hasCommunityInvitationLimit =
    customer.plan_limits.community_invitation_7_day_limit !== null;

  const validateLimits = useCallback(() => {
    if (parseInt(limits.list_row_count, 10) > parseInt(limits.list_row_limit, 10)) {
      setError("List row count cannot exceed row limit");
      return false;
    }

    if (parseInt(limits.list_row_limit, 10) > 500000) {
      setError("Enter a lower list row limit");
      return false;
    }

    if (parseInt(limits.list_row_delta, 10) > 500000) {
      setError("Enter a lower list row delta");
      return false;
    }
    if (
      limits.community_invitation_7_day_limit &&
      parseInt(limits.community_invitation_7_day_limit, 10) > 10000
    ) {
      setError("7 day community invitation limit must be below 10,000");
      return false;
    }

    if (parseInt(limits.edit_user_limit, 10) > 1000) {
      setError("Enter a lower edit user limit");
      return false;
    }

    if (
      [
        limits.edit_user_limit,
        limits.list_row_count,
        limits.list_row_limit,
        limits.list_row_delta,
        limits.community_invitation_7_day_limit,
      ].includes("")
    ) {
      setError("Enter values for all limits");
      return false;
    }

    return true;
  }, [limits]);

  const handleSave = () => {
    setError(null);
    setIsSaving(true);

    if (!validateLimits()) {
      setIsSaving(false);
      return;
    }

    updateCustomerLimits({
      limits,
      customer_id: customer.id,
      customer_type: customer.type,
    })
    .then(() => {
      onUpdate(limits);
      setIsSaving(false);
      setError(null);
    })
    .catch((err) => {
      setError(err.msg || GENERIC_ERROR_MESSAGE);
    });
  };

  const handleUpdate = (value, field) => {
    setLimits(curr => ({ ...curr, [field]: value }));
  };

  const handleClose = () => {
    onCancel();
    setIsSaving(false);
    setError(null);
  }

  return (
    <Modal
      title="Edit Limits"
      isOpen={isModalOpen}
      onClose={handleClose}
      onAction={handleSave}
      loading={isSaving}
      buttonTitle="Save"
    >
      { error && <InlineError message={error} /> }

      <Box padding="200">
        <FormLayout>

        <TextField
          value={limits.list_row_limit}
          onChange={(value) => handleUpdate(value.replace(/[^0-9]/g, ""), "list_row_limit")}
          label="List Row Limit"
          type="text"
        />

        <TextField
          value={limits.list_row_delta}
          onChange={(value) => handleUpdate(value.replace(/[^0-9]/g, ""), "list_row_delta")}
          label="List Row Delta"
          type="text"
        />

        {hasCommunityInvitationLimit ? (
          <TextField
            value={limits.community_invitation_7_day_limit}
            onChange={(value) =>
              handleUpdate(
                value.replace(/[^\d]/g, ""),
                "community_invitation_7_day_limit"
              )}
            label="Community Invitations 7 day limit"
            type="text"
          />
        ) : (
          <Text as="p" fontWeight="semibold">
            Unlimited Community Invitations
          </Text>
        )}

        </FormLayout>
      </Box>
    </Modal>
  );
}
