export function countryCodeToName(code) {
  if (typeof code !== "string") {
    return '';
  }

  const countryNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
  const countryName = countryNamesInEnglish.of(code);

  return countryName
}
