import React, { useEffect, useState, useRef } from 'react';
import * as api from '../../../services/api';
import { formatDate } from '../../../services/utils';
import {
  Page,
  TextField,
  Link,
  BlockStack,
  DataTable,
  Icon,
  InlineError,
  Card,
  Badge,
  Avatar,
  Text,
  InlineStack,
  EmptySearchResult,
  Box,
} from '@shopify/polaris';
import {SearchIcon} from '@shopify/polaris-icons';

import useDebouncedInput from '../../../hooks/useDebouncedInput';
import PageSpinner from "../../../components/page_spinner/PageSpinner";

import { GENERIC_ERROR_MESSAGE, paths } from "../../../constants";

const PAGE_SIZE = 50;

const STATUS_COLORS = {
  "applicant": "attention",
  "joined": "success",
  "unverified": "subdued",
}

const STATUS_NAMES = {
  "unverified": "Unverified Email",
  "applicant": "Pending Applicant",
  "joined": "Signed Up",
}

export default function CreatorsTable() {
  const [creators, setCreators] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadedPages, setLoadedPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [cursor, setCursor] = useState(null);
  const [search, setSearch] = useState('');
  const [error, setError] = useState(null);
  const creatorsPageTopRef = useRef(null);
  const debouncedSearch = useDebouncedInput(search, 300);

  useEffect(() => {
    setIsLoading(true);
    setLoadedPages(1);
    setCurrentPage(1);
    setCursor(null);
    setError(null);

    api
      .getCreators({ query: debouncedSearch, per_page: PAGE_SIZE })
      .then(res => {
        setCreators(res.creators);
        setCursor(res.cursor);
      })
      .catch(error => {
        console.log(error)
        setError(error.msg || GENERIC_ERROR_MESSAGE)
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [debouncedSearch, error]);

  const handlePageChange = (_, page) => {
    setError(null);
    if (page > loadedPages) {
      setIsLoading(true);
      api
        .getCreators({ query: debouncedSearch, per_page: PAGE_SIZE, cursor })
        .then(res => {
          setCurrentPage(page);
          setCreators(curr => [...curr, ...res.creators]);
          setCursor(res.cursor);
          setLoadedPages(page);
          creatorsPageTopRef.current.scrollIntoView();
        })
        .catch(error => {
          setError(error.msg || GENERIC_ERROR_MESSAGE)
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setCurrentPage(page);
      creatorsPageTopRef.current.scrollIntoView();
    }
  };

  const rowMarkup = creators
  .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
  .map(creator => (
      [
        <InlineStack wrap={false} gap="300">
          <Avatar 
            key={creator.profile.id}
            source={creator.avatar}
            customer
            size="xl"
            name={`${creator.profile.first_name} ${creator.profile.last_name}`}
          />
           <BlockStack gap="100">
            <Link
              url={paths.creator.main.replace(':creatorId', creator.profile.id)}
              key={"creator-" + creator.id}>
                <Text as="p" variant="headingMd">
                  {`${creator.profile.first_name} ${creator.profile.last_name}`}
                </Text>
              </Link>                
              <Text variant="bodysm" as="p">{creator.profile.display_name}</Text>
            </BlockStack>
        </InlineStack>,
        (
          (creator.email === creator.profile.email) 
            ? <Text as="p" variant="bodysm">{creator.email}</Text>
            : (
              <BlockStack gap="100">
                <Text as="p" variant="bodysm">Login: {creator.email}</Text>
                <Text as="p" variant="bodysm">Contact: {creator.profile.email}</Text>
              </BlockStack>
            )
        ),
        <Badge size="large" tone={STATUS_COLORS[creator.status]}>
          {STATUS_NAMES[creator.status]}
        </Badge>,
        formatDate({ value: new Date(creator.created_at), strftime: "%b %d, %Y", unix: true }),
     ]
    ),
  );

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No creators yet'}
      description={'Try changing the search term'}
      withIllustration
    />
  );

  return (
    <Page
      fullWidth
      title="Creators"
    >
      <Box paddingBlockEnd="1200">
        <BlockStack gap="500" align="space-around">

          <Box maxWidth="50%">
            <TextField
              value={search}
              onChange={(value) => setSearch(value)}
              prefix={<Icon source={SearchIcon} />}
            />
          </Box>

          { error && <InlineError message={error} /> }

          {!error && isLoading && (
            <PageSpinner />
          )}

          {!error && !isLoading && !Boolean(creators.length) && (
            <Card>
              {emptyStateMarkup}
            </Card>
          )}

          {!error && !isLoading && Boolean(creators.length) && (
            <Card padding="0">
              <DataTable
                itemCount={creators.length}
                columnContentTypes={[
                  'text',
                  'text',
                  'text',
                  'numeric',
                ]}
                stickyHeader
                verticalAlign="middle"
                headings={[
                  'Name',
                  'Email',
                  'Status',
                  'Created At',
                ]}
                rows={rowMarkup}
                pagination={{
                  hasNext: currentPage < loadedPages || cursor,
                  hasPrevious: currentPage > 1,
                  onNext: (e) => handlePageChange(e, currentPage + 1),
                  onPrevious: (e) => handlePageChange(e, currentPage - 1),
                }}
              >
              </DataTable>
            </Card>
          )}  
        </BlockStack>
      </Box>
    </Page>
  );
}
