import { timeFormat } from "d3-time-format";

export const formatDate = (props) => {
  const { value, strftime, unix } = props;
  if (unix) {
    if (strftime !== undefined) {
      const formatTime = timeFormat(strftime);
      const unixTime = new Date(value * 1000);
      return formatTime(unixTime);
    }
    return new Date(value * 1000).toDateString();
  }
  const formatTime = timeFormat(strftime);
  return formatTime(new Date(value));
};
