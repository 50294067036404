import React from 'react';
import { Card, Box } from '@shopify/polaris';
import { CardHeader, CardField } from '../../../../components/card';

export default function Socials({ creator }) {
  return (
    <Card>
      <CardHeader title="Social Accounts" />

      <Box paddingBlock="400">
        {creator.claims.filter(claim => claim.url).map(claim => (
          <CardField title={claim.url}></CardField>
        ))}
        {creator.accounts.map(account => (
          <CardField title={account.url}>
            {account.account_type ? account.account_type : null}
          </CardField>
        ))}
      </Box>
    </Card>
  );
}
