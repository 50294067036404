
import axios from "axios";
import collabsBaseURL from './collabsBaseURL';
const { CancelToken } = axios;
const cancelFactory = CancelToken.source();
export const cancel = () => cancelFactory.cancel();

const updateCSRFToken = (headers) => {
  if (headers["x-csrf-token"] !== undefined) {
    let csrfElement = document.getElementsByName("csrf-token")[0];
    if (!csrfElement) {
      csrfElement = document.createElement("meta");
      csrfElement.name = "csrf-token";
      document.head.appendChild(csrfElement);
    }
    csrfElement.content = headers["x-csrf-token"];
  }
};

export function initShopifyIdentity() {
  const redirectUrl = window.location.href;

  window.location.href = `${collabsBaseURL}/employee/login?redirect_url=${encodeURIComponent(redirectUrl)}`;
}

export const makeRequest = (options) => {
  options = {
    baseURL: collabsBaseURL,
    withCredentials: true,
    method: "get",
    headers: {
      'Accept': "application/json",
    },
    cancelToken: cancelFactory.token,
    ...options,
  };


  if (options.method !== "get") {
    options.headers["X-CSRF-Token"] = document.getElementsByName("csrf-token")[0]?.content;
  }

  return new Promise((resolve, reject) => {
    axios(options)
      .then((res) => {
        resolve(res.data);
        updateCSRFToken(res.headers);
      })
      .catch((err) => {
        if (document.readyState === "complete" || document.readyState === "interactive") {
          // Handle network errors
          if (err.message === "Network Error") {
            reject({ error: err, msg: "Network Error" });
          }

          // Log errors without HTTP responses
          if (err.response === undefined) {
            // captureError(err, options);
            return;
          }

          // We want to update csrf token either way.
          updateCSRFToken(err.response.headers);

          // Exit when axios requests are cancelled
          if (axios.isCancel(err)) {
            return;
          }

          if (err.response.status === 401) {
            return initShopifyIdentity();
          }

          if (err.response.status >= 500) {
            // captureError(err, options);
          }

          if (err.response.status === 500) {
            reject({ error: err, msg: "Something went wrong, please contact support." });
            return;
          } else if (err.response.status === 403) {
            reject({ error: err, msg: err.response.data.error });
            return;
          }

          reject({
            error: err,
            msg: err.response && (err.response.data.error || err.response.data.message),
          });
        }
      });
  });
};
