import React from "react";

import {
  Card,
  BlockStack,
  Text,
  Thumbnail,
  Button,
  InlineStack,
  Layout,
} from '@shopify/polaris';
import { PersonIcon } from '@shopify/polaris-icons';

import { paths } from "../../../../constants";

export default function MerchantDetails({ merchant }) {
  const handleCollabsImpersonation = () => {
    if (merchant.shopify_store.domain) {
      window.open(paths.external.shopifyStore.replace(':store', merchant.shopify_store.domain), "_blank");
    }
  };

  const handleGraphiQL = () => {
    if (merchant.shopify_store?.shopify_store_id) {
      window.open(
        paths.external.storeGraphiQL.replace(':shopify_store_id', merchant.shopify_store?.shopify_store_id),
        "_blank",
      );
    }
  };

  return (
    <Card>
      <Layout>
        <Layout.Section>
          <InlineStack gap="500" blockAlign="center">
              <Thumbnail
                source={merchant.avatar || PersonIcon}
                size="large"
                alt={merchant.name}
              />
            <BlockStack gap="100">
              <Text variant="bodysm" as="p" tone="subdued">
                {merchant.type} (ID: {merchant.id})
              </Text>
              {merchant.shopify_store && (
                <Text variant="bodysm" as="p" tone="subdued">
                  Shopify Store ID (ID: {merchant.shopify_store.shopify_store_id})
                </Text>
              )}
            </BlockStack>
          </InlineStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <InlineStack align="end" gap="400">
            {merchant.shopify_store?.shopify_store_id && (
              <Button
                variant="secondary"
                size="large"
                onClick={handleGraphiQL}
              >
                Store GraphiQL
              </Button>
            )}
            {merchant.shopify_store && (
              <Button
                variant="primary"
                size="large"
                onClick={handleCollabsImpersonation}
              >
                Sign in to Shopify store
              </Button>
            )}
          </InlineStack>
        </Layout.Section>
      </Layout>
    </Card>
  );
}
