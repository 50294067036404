import React, { useState } from 'react';
import {
  Card,
  Text,
  Thumbnail,
  Button,
  InlineStack,
  Layout,
} from '@shopify/polaris';
import { PersonIcon } from '@shopify/polaris-icons';

import { paths } from '../../../../constants';

export default function CreatorDetails({ creator }) {
  const [isLoading, setIsLoading] = useState();

  const handleCollabsImpersonation = () => {
    window.open(paths.creator.login.replace(':uuid', creator.identity_uuid), "_blank");
  };

  const handleGraphiQL = () => {
    const impersonationUrl =paths.creator.login.replace(':uuid', creator.identity_uuid)
    const creatorGraphiqlUrl = `${impersonationUrl}?return_url=${paths.external.creatorGraphiQL}`;

    window.open(encodeURI(creatorGraphiqlUrl), "_blank");
  };

  const handleViewApplication = () => {
    if (!creator.token) return;

    const url = paths.creator.community.replace(':token', creator.token);

    setIsLoading(true)

    if (process.env.NODE_ENV === "development") {
      window.location = window.location.origin.replace("admin", "frontend") + url;
    } else {
      window.location = window.location.origin.replace("admin.", "") + url;
    }
  }

  return (
    <Card>
      <Layout>
        <Layout.Section>
          <InlineStack gap='500' blockAlign='center'>
            <Thumbnail
              source={creator.avatar || PersonIcon}
              size='large'
              alt={creator.profile.display_name}
            />
            <Text variant='bodysm' as='p' tone='subdued'>
              {creator.type} (ID: {creator.id})
            </Text>
          </InlineStack>
        </Layout.Section>
        <Layout.Section variant='oneThird'>
          <InlineStack align='end' gap='400'>
            {creator.identity_uuid && (
              <Button variant='secondary' size='large' onClick={handleGraphiQL}>
                GraphiQL
              </Button>
            )}
            {creator.identity_uuid && (
              <Button
                variant='primary'
                size='large'
                onClick={handleCollabsImpersonation}
              >
                Sign in as creator to Collabs
              </Button>
            )}
            {creator.token && creator.status === "applicant" && (
              <Button
                variant='primary'
                size='large'
                loading={isLoading}
                onClick={handleViewApplication}
              >
                View Application
              </Button>
            )}
          </InlineStack>
        </Layout.Section>
      </Layout>
    </Card>
  );
}
