import { makeRequest } from "./utils";

export const getProducts = (params) =>
  makeRequest({
    method: "get",
    url: "/api/admin/products",
    params,
  });

export const updateProductsVisibility = (data) =>
  makeRequest({
    method: "put",
    url: "/api/admin/products/update_visibility",
    data,
  });
