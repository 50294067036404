import React from "react";

import {
  Spinner,
} from '@shopify/polaris';

import styles from './PageSpinner.module.css';

export default function PageSpinner() {
return (
  <div className={styles.LoadingWrapper}>
    <Spinner accessibilityLabel="Data is loading" size="large" />
  </div>
)}
