import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { 
  BlockStack,
  TextField,
  InlineError, 
  Box,
} from '@shopify/polaris';

import { createCuratedCollection } from "../../../services/api";
import CategorySelect from "../common/CategorySelect";

import { GENERIC_ERROR_MESSAGE, paths } from "../../../constants";

import Modal from '../../../components/modal/Modal';

export default function NewCuratedCollectionModal({ isModalOpen, onClose }) {
  const [error, setError] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [collection, setCollection] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const navigate = useNavigate();

  const handleChangeTitle = (value) => {
    setCollection((curr) => ({ ...curr, title: value }));
  };

  const handleChangeDescription = (value) => {
    setCollection((curr) => ({ ...curr, description: value }));
  };

  const handleCreate = () => {
    setError('');
    setTitleError(false);

    if (!collection.title) {
      setTitleError(true);
      return;
    }

    setIsCreating(true);

    createCuratedCollection({ collection: { ...collection } })
      .then((res) => {
        navigate(paths.curatedCollection.main.replace(':collectionId', res.id));
      })
      .catch((err) => {
        setError(err.msg || GENERIC_ERROR_MESSAGE);
        setIsCreating(false);
      });
  };

  useEffect(() => {
    setCollection((curr) => ({ ...curr, categories: selectedCategories }));
  }, [selectedCategories]);

  const handleClose = () => {
    setCollection({});
    setSelectedCategories([]);
    setError('');
    setIsCreating(false);
    onClose();
  }

  return (
    <Modal
      title="New Collection"
      isOpen={isModalOpen}
      onClose={handleClose}
      onAction={handleCreate}
      loading={isCreating}
      buttonTitle={"Create"}
      actionDisabled={!Boolean(collection)}
    >
      <Box padding="200">
        <BlockStack gap="400">

          { error && <InlineError message={error} /> }

          <TextField
            label="Title"
            value={collection.title}
            onChange={handleChangeTitle}
            autoComplete="off"
            error={titleError && "Title can not be blank"}
          />

          <TextField
            label="Description"
            value={collection.description}
            onChange={handleChangeDescription}
            multiline={5}
            autoSize
            autoComplete="off"
          />

          <CategorySelect onChange={setSelectedCategories} />

        </BlockStack>
      </Box>
    </Modal>
  );
}
