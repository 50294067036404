import React, { useEffect, useState } from 'react';
import { getCustomers } from '../../../services/api';
import { formatDate } from '../../../services/utils';

import {
  Page,
  TextField,
  Link,
  BlockStack,
  DataTable,
  Icon,
  InlineError,
  Card,
  EmptySearchResult,
  Box,
} from '@shopify/polaris';
import {SearchIcon} from '@shopify/polaris-icons';

import useDebouncedInput from '../../../hooks/useDebouncedInput';
import PageSpinner from "../../../components/page_spinner/PageSpinner";

import { GENERIC_ERROR_MESSAGE, paths } from "../../../constants";

const PAGE_SIZE = 50;

export default function MerchantsTable() {
  const [merchants, setMerchants] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadedPages, setLoadedPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [cursor, setCursor] = useState(null);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebouncedInput(search, 300);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setLoadedPages(1);
    setCurrentPage(1);
    setCursor(null);
    setError(null);

    getCustomers({ query: debouncedSearch, per_page: PAGE_SIZE })
      .then(res => {
        setMerchants(res.customers);
        setCursor(res.cursor);
      })
      .catch((error) => setError(error.msg || GENERIC_ERROR_MESSAGE))
      .finally(() => {
        setIsLoading(false);
      });;
  }, [debouncedSearch, error]);

  const handlePageChange = (_, page) => {
    setError(null);
    if (page > loadedPages) {
      setIsLoading(true);
      getCustomers({ query: debouncedSearch, per_page: PAGE_SIZE, cursor })
        .then(res => {
          setCurrentPage(page);
          setMerchants(curr => [...curr, ...res.customers]);
          setCursor(res.cursor);
          setLoadedPages(page);
        })
        .catch((error) => {
          setError(error.msg || GENERIC_ERROR_MESSAGE);
        })
        .finally(res => {
          setIsLoading(false);
        });
    } else {
      setCurrentPage(page);
    }
  };

  const rowMarkup = merchants
  .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
  .map(merchant => (
      [
        merchant.id,
        merchant.type,
        <Link
          url={
            merchant.type === "Agency"
            ? paths.merchants.agency.replace(':merchantId', merchant.id)
            : paths.merchants.brand.replace(':merchantId', merchant.id)
          }
          key={"merchant-" + merchant.id}
        >
          {merchant.name}
        </Link>,
        formatDate({ value: new Date(merchant.created_at), strftime: "%b %d, %Y", unix: true }),
     ]
    ),
  );

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No merchants yet'}
      description={'Try changing the search term'}
      withIllustration
    />
  );

  return (
    <Page
      fullWidth
      title="Merchants"
    >
      <Box paddingBlockEnd="1200">
        <BlockStack gap="500" align="space-around">

          <Box maxWidth="50%">
            <TextField
              value={search}
              onChange={(value) => setSearch(value)}
              prefix={<Icon source={SearchIcon} />}
            />
          </Box>

          { error && <InlineError message={error} /> }

          {!error && isLoading && (
            <PageSpinner />
          )}

          {!error && !isLoading && !Boolean(merchants.length) && (
            <Card>
              {emptyStateMarkup}
            </Card>
          )}

          {!error && !isLoading && Boolean(merchants.length) && (
            <Card padding="0">
              <DataTable
                itemCount={merchants.length}
                columnContentTypes={[
                  'text',
                  'text',
                  'text',
                  'numeric',
                ]}
                stickyHeader
                headings={[
                  'ID',
                  'Type',
                  'Name',
                  'Created At',
                ]}
                rows={rowMarkup}
                pagination={{
                  hasNext: currentPage < loadedPages || cursor,
                  hasPrevious: currentPage > 1,
                  onNext: (e) => handlePageChange(e, currentPage + 1),
                  onPrevious: (e) => handlePageChange(e, currentPage - 1),
                }}
              >
              </DataTable>
            </Card>
          )}

        </BlockStack>
      </Box>
    </Page>
  );
}
