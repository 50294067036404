/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  Page, 
  BlockStack,
  InlineError,
  Layout,
  EmptyState,
  Card,
  Box,
} from '@shopify/polaris';
import { getCreator } from '../../../services/api';

import Socials from './components/Socials';
import Profile from './components/Profile';
import Discovery from './components/Discovery';
import CreatorDetails from './components/CreatorDetails';
import PageSpinner from "../../../components/page_spinner/PageSpinner";

import { GENERIC_ERROR_MESSAGE, paths, images } from "../../../constants";

export default function Creator() {
  const [creator, setCreator] = useState();
  const { creatorId } = useParams();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadCreator = () => {
    setError(null);
    setIsLoading(true);

    return getCreator(creatorId)
      .then(res => setCreator(res))
      .catch(error => {
        setError(error.msg || GENERIC_ERROR_MESSAGE)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadCreator();
  }, []);

  return (
    <Page
      backAction={{content: '', url: paths.admin.creators}}
      title={creator?.profile?.display_name || ''}
      fullWidth
    >
      <BlockStack gap="500" align="space-around">
      
      {error && !isLoading && <InlineError message={error} />}

      {isLoading && (
        <PageSpinner />
      )}

      {!creator && !error && !isLoading && (
        <Card>
          <EmptyState
            heading="Creator not found"
            image={images.emptyStateImage}
          >
            Please try reloading the page or contact the #collabs-devs Slack channel if the creator is still not found.
          </EmptyState>
        </Card>
      )}

      {creator && !isLoading && (
        <Box paddingBlockEnd="400">
          <BlockStack gap="500" align="space-around">
            <CreatorDetails creator={creator} />
            <Layout>
              <Layout.Section variant="oneHalf">
                <BlockStack gap="500">
                  <Profile creator={creator} />
                </BlockStack>
              </Layout.Section>
              <Layout.Section>
                <BlockStack gap="500">
                  <Discovery creator={creator} setCreator={setCreator} />
                  <Socials creator={creator} />
                </BlockStack>
              </Layout.Section>
            </Layout>
          </BlockStack>
        </Box>
        )}
      </BlockStack>
    </Page>
  );
}
