import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Text,
  BlockStack,
  Box,
  InlineStack,
  Button,
} from '@shopify/polaris';

import { paths } from "../../constants";

export default function EmptyPage() {

  const navigate = useNavigate();

  return (
    <Box padding="2800" width="100%">
      <BlockStack gap="500" align="center">
        <InlineStack gap="500" align="center" blockAlign="center">
            <Text as="h1" variant="headingXl">Page doesnt exist</Text>
          </InlineStack>
          <InlineStack gap="500" align="center" blockAlign="center">
            <Text as="p" variant="bodyLg">Is it something unexpected? Please contact #collabs-devs slack channel.</Text>
          </InlineStack>
          <InlineStack gap="500" align="center" blockAlign="center">
            <Button variant="primary" size="large" onClick={() => navigate(paths.home)}>Go Home</Button>
          </InlineStack>
      </BlockStack>
    </Box>
  );
}
