let baseURL;
if (process.env.REACT_APP_SPIN_DOVETALE_API_SERVICE_FQDN !== undefined && process.env.REACT_APP_SPIN_DOVETALE_API_SERVICE_FQDN.length > 0) {
  baseURL = `https://${process.env.REACT_APP_SPIN_DOVETALE_API_SERVICE_FQDN}`;
} else if (process.env.NODE_ENV === "production") {
  baseURL = "https://api.collabs.shopify.com";
} else if (process.env.NODE_ENV === "staging") {
  baseURL = "https://staging-api.dovetale.org";
} else if (process.env.NODE_ENV === "development") {
  baseURL = "http://api.dovetale.test";
} else {
  throw new Error("Incorrect BaseURL");
}

export default baseURL;