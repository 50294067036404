import React from "react";

import {
  BlockStack,
  Layout,
  InlineStack,
  Card,
  Text,
  Button,
  ButtonGroup,
  Box,
} from '@shopify/polaris';
import { PlusIcon, EditIcon, DeleteIcon } from "@shopify/polaris-icons";

import CollectionStatus from "../../common/CollectionStatus";

export default function CuratedCollectionHeader({
    collection,
    openEditCuratedCollectionModal,
    openAddProductModal,
    deleteCollection,
    productCategories,
  }) {

  return (
    <Card>
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            <InlineStack gap="500" blockAlign="center">
              <CollectionStatus status={collection.status} />
              <Text variant="headingXl" as="h4">
                {collection.title}
              </Text>
            </InlineStack>

    
            {collection.description && (
              <Text as="p" variant="bodyMd">
                {collection.description}
              </Text>
            )}

            {collection.categories?.length > 0 && (
              <Box>
                <Text as="h5" variant="headingMd" fontWeight="medium">Product categories:</Text>

                {collection.categories.map((category) => (
                  <Text key={category} as="p" variant="bodyMd"  tone="subdued">
                    {productCategories[category]}
                  </Text>
                ))}
              </Box>
            )}
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <InlineStack align="end">
            <ButtonGroup>
              <Button
                variant="primary"
                icon={EditIcon}
                onClick={openEditCuratedCollectionModal}
              >
                Edit
              </Button>
              <Button
                variant="primary"
                icon={PlusIcon}
                onClick={openAddProductModal}
              >
                Add product
              </Button>
              <Button
                variant="primary"
                tone="critical"
                icon={DeleteIcon}
                onClick={deleteCollection}
              >
                Delete
              </Button>
            </ButtonGroup>
          </InlineStack>
        </Layout.Section>
      </Layout>
    </Card>
  );
}
