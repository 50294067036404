import React from "react";
import {
  Text,
  BlockStack,
  Box,
  InlineStack,
} from '@shopify/polaris';


export default function UnauthorizedPage() {

  return (
    <Box padding="2800" width="100%">
      <BlockStack gap="500" align="center">
        <InlineStack gap="500" align="center" blockAlign="center">
            <Text as="h1" variant="headingXl">You have no permissions to visi this page</Text>
          </InlineStack>
        <InlineStack gap="500" align="center" blockAlign="center">
            <Text as="p" variant="bodyLg">Is it something unexpected? Please contact #collabs-devs slack channel.</Text>
          </InlineStack>
      </BlockStack>
    </Box>
  );
}
