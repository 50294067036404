/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react';

export default function useDebouncedInput(value, wait) {
  const [input, setInput] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setInput(value);
    }, wait);

    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  return input;
}