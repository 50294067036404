import { makeRequest } from "./utils";

export const getCommissions = (params) =>
  makeRequest({
    method: "get",
    url: "/api/admin/commissions",
    params,
  });

export const getCommissionsByCreator = (creator_id) =>
  makeRequest({
    method: "get",
    url: `/api/admin/commissions/creator/${creator_id}`,
 });

export const approveCommissionsPayout = (creator_id) =>
  makeRequest({
    method: "post",
    url: `/api/admin/commissions/creator/${creator_id}/approve`,
});

export const rejectCommissionsPayout = (creator_id) =>
  makeRequest({
    method: "post",
    url: `/api/admin/commissions/creator/${creator_id}/reject`,
});