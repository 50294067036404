import { makeRequest } from "./utils";

export const getCuratedCollections = () =>
  makeRequest({
    method: "get",
    url: "/api/admin/curated_collections",
  });

export const getCuratedCollection = (id) =>
  makeRequest({
    method: "get",
    url: `/api/admin/curated_collections/${id}`,
  });

export const removeCuratedCollection = (id) =>
  makeRequest({
    method: "delete",
    url: `/api/admin/curated_collections/${id}`,
  });

export const createCuratedCollection = (data) =>
  makeRequest({
    method: "post",
    url: `/api/admin/curated_collections`,
    data,
  });

export const updateCuratedCollection = (id, data) =>
  makeRequest({
    method: "put",
    url: `/api/admin/curated_collections/${id}`,
    data,
  });

export const getCuratedCollectionProducts = (id) =>
  makeRequest({
    method: "get",
    url: `/api/admin/curated_collections/${id}/products`,
  });

export const removeProductFromCuratedCollection = (id, productId) =>
  makeRequest({
    method: "delete",
    url: `/api/admin/curated_collections/${id}/products/${productId}`,
  });


export const addProductToCuratedCollection = (id, data) =>
  makeRequest({
    method: "post",
    url: `/api/admin/curated_collections/${id}/products`,
    data,
  });

export const getProductCategories = () =>
  makeRequest({
    method: "get",
    url: `/api/admin/product_categories`,
  });
