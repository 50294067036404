import React from "react";

import {
  BlockStack,
  Badge,
  Text,
  Box,
} from '@shopify/polaris';

export default function NetworkAvailableBadge({ networkAvailable }) {
  if (networkAvailable) {
    return (
    <Badge size="large" tone="success">Yes</Badge>
    );
  }

  return (
    <BlockStack gap={200}>
      <Box width="20px">
        <Badge size="large" tone="warning">
          No
        </Badge>
      </Box>
      <Text as="legend" tone="subdued" fontWeight="regular" variant="bodyXs">
        Only products available in the Collabs Network will be displayed.
      </Text>
    </BlockStack>
  );
}
