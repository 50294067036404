import React from "react";

import { formatDate } from "../../../../services/utils";

import {
  Card,
  Text,
  Box,
  Avatar,
  InlineStack,
  DataTable,
} from '@shopify/polaris';

import { CardHeader, CardField } from "../../../../components/card";

export default function MerchantTeam({ merchant }) {

  const rows = merchant.team_members.map((member, index) => [
    <InlineStack gap="200" align="start" blockAlign="center" key={`member-${index}`}>
      <Avatar source={member.avatar} customer name={member.name} size="lg" />
      <Box paddingInline="200">
        <Text as="p">
          {member.email}
        </Text>
        {member.name && (
          <Text as="p">
            {member.name}
          </Text>
        )}
      </Box>
    </InlineStack>,
    member.user_id
      ? `Joined on ${formatDate({
          value: new Date(member.joined_at),
          strftime: "%b %d, %Y",
        })}`
      : `Invited on ${formatDate({
          value: new Date(member.created_at),
          strftime: " %b %d, %Y",
        })}`,
  ]);

  return (
    <Card>
      <CardHeader title="Team" />

      {!merchant.team_members.length && <CardField title="No team members" />}

      <DataTable
          columnContentTypes={[
            'text',
            'text',
          ]}
          verticalAlign="middle"
          headings={[
            'Member',
            'Date',
          ]}
          rows={rows}
        />
    </Card>
  );
}