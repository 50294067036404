import React, { useState } from 'react';
import {
  Card, 
  Box,
  Text,
  Button,
  InlineStack,
  InlineError,
} from '@shopify/polaris';

import { updateCreatorWaitlist } from '../../../../services/api';
import { CardHeader, CardField } from '../../../../components/card';

import { GENERIC_ERROR_MESSAGE } from "../../../../constants";

export default function Discovery({ creator, setCreator }) {
  const [approving, setApproving] = useState(false)
  const [error, setError] = useState(null);

  const approveCreator = () => {
    setError(null);
    setApproving(true);

    updateCreatorWaitlist({ creator_id: creator.id, status: "approved" })
      .then((res) => {
        setCreator(curr => ({ ...curr, waitlist_status: res.status }))
        
      })
      .catch(error => {
        setError(error.msg || GENERIC_ERROR_MESSAGE)
      })
      .finally(() => {
        setApproving(false);
      });
  }

  const statusMarkup = (status) => {
    if (status === "PENDING") {
      return (
        <Box paddingBlockStart="300">
          <InlineStack gap="300" align="space-between">
            <Text as="p" fontWeight="strong" tone="subdued">
              Waitlist status
            </Text>
            <InlineStack gap="500" align="space-around" blockAlign="center">
              <Text>Pending</Text>
              <Button size="micro" variant="primary" onClick={approveCreator} loading={approving}>Approve</Button>
            </InlineStack>
          </InlineStack>
        </Box>
      )
    } else if (status === "SKIPPED") {
      return (
        <Box paddingBlockStart="300">
          <InlineStack gap="300" align="space-between">
            <Text as="p" fontWeight="strong" tone="subdued">
              Waitlist status
            </Text>
            <InlineStack gap="500" align="space-around" blockAlign="center">
              <Text>Skipped</Text>
              <Button size="micro" variant="primary" onClick={approveCreator} loading={approving}>Approve</Button>
            </InlineStack>
          </InlineStack>
        </Box>
      )
    } else if (status === "APPROVED") {
      return (
        <CardField title="Waitlist status">
          Approved
        </CardField>
      )
    } else if (status === "REJECTED") {
      return (
        <Box paddingBlockStart="300">
          <InlineStack gap="300" align="space-between">
            <Text as="p" fontWeight="strong" tone="subdued">
              Waitlist status
            </Text>
            <InlineStack gap="500" align="space-around" blockAlign="center">
              <Text>Rejected</Text>
              <Button size="micro" variant="primary" onClick={approveCreator} loading={approving}>Approve</Button>
            </InlineStack>
          </InlineStack>
        </Box>
      )
    } else {
      return (
        <CardField title="Waitlist status">
          Has not onboarded
        </CardField>
      )
    }
  }

  return (
    <Card>
      <CardHeader title="Discovery" />

      {error && !approving && <InlineError message={error} />}

      <Box paddingBlock="400">
        {statusMarkup(creator.waitlist_status)}
        <CardField title="Follower minimum met">
          {creator.waitlist_follower_minimum_met ? "Yes" : "No"}
        </CardField>
        <CardField title="Country restriction met">
          {creator.waitlist_country_restriction_met ? "Yes" : "No"}
        </CardField>
      </Box>
    </Card>
  )
}