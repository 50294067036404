import React from "react";

import {
  Card,
} from '@shopify/polaris';

import { CardHeader, CardField } from "../../../../components/card";

export default function MerchantStore({ merchant }) {
  const { shopify_store } = merchant;

  const emptyCardMarkup = (
    <Card>
      <CardHeader title="Shopify Store" />
      <CardField title="No shop connected" />
    </Card>
  );

  return (
    <>
      {!merchant.shopify_store && emptyCardMarkup}

      {merchant.shopify_store && (
        <Card>
          <CardHeader title="Shopify Store" />

          <CardField title="Status">
            {shopify_store.active ? "CONNECTED" : "DISCONNECTED"}
          </CardField>

          <CardField title="Domain">
            {shopify_store.domain}
          </CardField>

          <CardField title="Website">
            {shopify_store.domain_alias}
          </CardField>
                
          <CardField title="Total Orders">
            {shopify_store.total_orders || '-'}
          </CardField>

          <CardField title="Total Products">
            {shopify_store.total_products || '-'}
          </CardField>

          <CardField title="Affiliate Sales">
            {shopify_store.affiliate_sales || '-'}
          </CardField>

          <CardField title="Flipper ID">
            <code>{shopify_store.flipper_id}</code>
          </CardField>
      
          <CardField title="Discoverable">
            {shopify_store.discoverable ? "Active" : "Deactivated"}
          </CardField>
        </Card>
      )}
    </>
  );
}