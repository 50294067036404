let collabsBaseURL;

if (process.env.REACT_APP_SPIN_DOVETALE_API_SERVICE_FQDN !== undefined && process.env.REACT_APP_SPIN_DOVETALE_API_SERVICE_FQDN.length > 0) {
  collabsBaseURL = `https://${process.env.REACT_APP_SPIN_DOVETALE_API_SERVICE_FQDN}`;
} else if (process.env.NODE_ENV === "production") {
  collabsBaseURL = "https://api.collabs.shopify.com";
} else if (process.env.NODE_ENV === "staging") {
  collabsBaseURL = "https://staging-api.collabs.shopify.com";
} else if (process.env.NODE_ENV === "development") {
  collabsBaseURL = "http://api.dovetale.test";
} else {
  throw new Error("Incorrect collabsBaseURL");
}

export default collabsBaseURL;
