/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Page, 
  BlockStack,
  InlineError,
  Layout,
  EmptyState,
  Card,
} from '@shopify/polaris';

import { getCustomer } from "../../../services/api";

import MerchantDetails from "./components/MerchantDetails";
import MerchantTeam from "./components/MerchantTeam";
import MerchantStore from "./components/MerchantStore";
import MerchantSubscription from "./components/MerchantSubscription";
import MerchantPayouts from "./components/MerchantPayouts";
import PageSpinner from "../../../components/page_spinner/PageSpinner";

import { GENERIC_ERROR_MESSAGE, paths, images } from "../../../constants";

export default function Merchant() {
  const [merchant, setMerchant] = useState(null);
  const { merchantId } = useParams();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setError(null);

    const merchant_type = /merchants\/agencies/.test(window.location.pathname)
      ? "Agency"
      : "Brand";

    getCustomer({ customer_id: merchantId, customer_type: merchant_type })
    .then((res) => {
      setMerchant({
        ...res,
        type: merchant_type,
      });
    })
    .catch(error => {
      setError(error.msg || GENERIC_ERROR_MESSAGE)
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <Page
      backAction={{content: '', url: paths.admin.merchants}}
      title={merchant?.name || ''}
      fullWidth
    >
      <BlockStack gap="500" align="space-around">

        {error && !isLoading && <InlineError message={error} />}

        {isLoading && (
          <PageSpinner /> 
        )}

        {!error && !merchant && !isLoading && (
        <Card>
          <EmptyState
            heading="Merchant not found"
            image={images.emptyStateImage}
          >
            Please try reloading the page or contact the #collabs-devs Slack channel if the merchant is still not found.
          </EmptyState>
        </Card>
        )}

        {merchant && !isLoading && (
          <BlockStack gap="500" align="space-around">
            <MerchantDetails merchant={merchant} />
            <Layout>
              <Layout.Section variant="oneHalf">
                <BlockStack gap="500">
                  <MerchantStore merchant={merchant} />
                  <MerchantSubscription
                    merchant={merchant}
                    setMerchant={setMerchant}
                  />
                </BlockStack>
              </Layout.Section>
              <Layout.Section>
                <BlockStack gap="500">
                  <MerchantPayouts merchant={merchant} />
                  <MerchantTeam merchant={merchant} />
                </BlockStack>
              </Layout.Section>
            </Layout>
          </BlockStack>
        )}
      </BlockStack>
    </Page>
  );
}
