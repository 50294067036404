/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";

import { Page, BlockStack, Box, Button } from "@shopify/polaris";
import { getDiagnostics } from "../../services/api";

export default function Diagnostics() {
  const loadDiagnostics = () => {
    return getDiagnostics().catch((error) => {});
  };

  return (
    <Page fullWidth>
      <BlockStack gap="500" align="space-around">
        <Box paddingBlockEnd="400">
          <BlockStack gap="500" align="space-around">
            <Box>
              <Button onClick={loadDiagnostics}>
                Load Diagnostics & crash web pod, don't touch this unless you
                know what you are doing
              </Button>
            </Box>
          </BlockStack>
        </Box>
      </BlockStack>
    </Page>
  );
}
