import { makeRequest } from "./utils";

////
// Diagnostics
//
export const getDiagnostics = (params) =>
  makeRequest({
    method: "get",
    url: "/api/admin/diagnostics",
    params,
  });