import React from "react";

import { CardField } from "../../../../components/card";

export default function AutoCommissionEnrollmentSource({ source }) {
  switch (source) {
    case "enrolled_manually":
    case "enrolled_automatically":
      return (
        <CardField title="Automatic Payments Enrollment Source">
          {source === "enrolled_automatically" ? "Automatic" : "Manual"}
        </CardField>
      );
    case "not_enrolled":
    default:
      return null;
  }
}