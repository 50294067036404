import React, { useEffect, useState } from "react";
import {
  Page,
  TextField,
  BlockStack,
  IndexTable,
  Icon,
  InlineError,
  Card,
  useBreakpoints,
  EmptySearchResult,
  Box,
} from '@shopify/polaris';
import { SearchIcon } from '@shopify/polaris-icons';
import { Link } from 'react-router-dom'; 

import { getCommissions } from "../../services/api/commissions"; 
import { GENERIC_ERROR_MESSAGE } from "../../constants";

import useDebouncedInput from "../../hooks/useDebouncedInput";
import PageSpinner from "../../components/page_spinner/PageSpinner";
import { paths } from  "../../constants";

import styles from './CommissionsTable.module.css';

const PAGE_SIZE = 20;

export default function CommissionsTable() {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [actionableCommissionsSummary, setActionableCommissionsSummary] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [loadedPages, setLoadedPages] = useState(1);
  const [cursor, setCursor] = useState(null);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebouncedInput(search, 300);
  const [error, setError] = useState(null);

  const resourceName = {
    singular: 'commission',
    plural: 'commissions',
  };

  const { breakpoint } = useBreakpoints();

  useEffect(() => {
    setError(null);
    setIsPageLoading(true);
    setLoadedPages(1);
    setCurrentPage(1);

    getCommissions({
      query: debouncedSearch,
      per_page: PAGE_SIZE,
    })
      .then((res) => {
        setActionableCommissionsSummary(res.actionable_commissions_summary || {}); 
        setCursor(res.cursor);
      })
      .catch(error => {
        setError(error.msg || GENERIC_ERROR_MESSAGE);
        setActionableCommissionsSummary({});
      })
      .finally(() => {
        setIsPageLoading(false);
      });
  }, [debouncedSearch]);

  const handlePageChange = (_, page) => {
    setError(null);
    if (page > loadedPages) {
      setIsPageLoading(true);
      
      getCommissions({
        query: debouncedSearch,
        per_page: PAGE_SIZE,
        cursor,
      })
        .then((res) => {
          setCurrentPage(page);
          setActionableCommissionsSummary({...actionableCommissionsSummary, ...res.actionable_commissions_summary});
          setCursor(res.cursor);
          setLoadedPages(page); 
        })
        .catch(error => {
          setError(error.msg || GENERIC_ERROR_MESSAGE);
          setActionableCommissionsSummary({});
        })
        .finally(() => {
          setIsPageLoading(false);
        });
    } else {
      setCurrentPage(page);
    }
  };

  const rowMarkup = Object.keys(actionableCommissionsSummary)
    .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE).map((creatorStr) => {
    const [creatorId, creatorName] = JSON.parse(creatorStr);
    const actionableCommissionsCount = actionableCommissionsSummary[creatorStr];
    return (
      <IndexTable.Row
        id={creatorId}
        key={creatorId}
        position={creatorId}
      >
        <IndexTable.Cell className={styles.TableCell}>
          <Link to={paths.commissions.byCreator.replace(':creatorId', creatorId)}>{creatorName}</Link>
        </IndexTable.Cell>
        <IndexTable.Cell className={styles.TableCell}>{actionableCommissionsCount}</IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No commissions yet'}
      description={'Try changing the filters or search term'}
      withIllustration
    />
  );

  return (
    <Page
      fullWidth
      title="Commissions"
    >
      <Box paddingBlockEnd="1200">
        <BlockStack gap="500" align="space-around">

          <Box maxWidth="50%">
            <TextField
              value={search}
              onChange={(value) => setSearch(value)}
              prefix={<Icon source={SearchIcon} />} />
          </Box>

          {error && <InlineError message={error} />}

          {!error && isPageLoading && (
            <PageSpinner />
          )}

          {!error && !isPageLoading && (
            <Card padding="0">
              <IndexTable
                condensed={breakpoint?.smDown}
                resourceName={resourceName}
                itemCount={Object.keys(actionableCommissionsSummary).length}
                selectable={false}
                headings={[
                  { title: 'Creator Name' },
                  { title: 'Payments to Process' },
                ]}
                pagination={{
                  hasNext: currentPage < loadedPages || cursor,
                  hasPrevious: currentPage > 1,
                  onNext: (e) => handlePageChange(e, currentPage + 1),
                  onPrevious: (e) => handlePageChange(e, currentPage - 1),
                }}
                emptyState={emptyStateMarkup}
              >
                {rowMarkup}
              </IndexTable>
            </Card>
          )}
        </BlockStack>
      </Box>
    </Page>
  );
}